.title {
    font-size: 24px;
    font-weight: bold;
}

.categories {
    vertical-align: top;

    span {
        font-size: 25px;
    }
}