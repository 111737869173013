ul {
  &.categories {
    li {
      list-style: none;
    }

    &.root {
      padding-left: 0;
    }
  }

  .product {
    text-transform: none;
    font-weight: bold;
  }
}

.chips-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
}