.wrapper {
    display: inline-block;
    position: relative;
    z-index: 10;
}

.container {
    position: absolute;
    top: 100%;
    right: 0;
    display: flex;
    flex-flow: column nowrap;
    background-color: #ffffff;
    width: max-content;
    gap: 15px;
    padding-top: 10px;

    & > fieldset {
        display: flex;
        flex-flow: column nowrap;
        gap: 15px;
        border: 1px solid #e3e3e3;

        & > fieldset {
            display: flex;
            flex-flow: row nowrap;
            gap: 10px;
            border: 1px solid #e3e3e3;
        }
    }
}