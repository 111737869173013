.wrapper {
    position: relative;
    z-index: 10;
}

.container {
    position: absolute;
    top: 100%;
    right: 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
    width: max-content;

    .item {
        box-sizing: border-box;
        width: 100%;
        padding: 10px;
        cursor: pointer;
        transition: background-color 0.3s;
        text-align: left;

        &:hover, &.active {
            background-color: #f5f5f5;
        }

        &.active {
            color: #000;
        }
    }
}