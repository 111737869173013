.head {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-auto-rows: min-content;
    gap: 10px;

    .title {
        font-weight: bold;
    }
}

.shelf-image {
    position: relative;

    .remove-button {
        all: unset;
        position: absolute;
        top: 5px;
        right: 5px;
        background-color: red;
        color: white;
        display: none;
        align-items: center;
        justify-content: center;
        padding: 2px 5px;
        font-size: 14px;
        cursor: pointer;
        line-height: 14px;
    }

    &:hover {
        .remove-button {
            display: flex;
        }
    }
}
