.title1 {
    font-size: 26px;
    font-weight: bold;
}

.title2 {
    font-size: 22px;
    font-weight: bold;
}

.head {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}