.button {
  position: absolute !important;
  top: 10px;
  right: 10px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  background-color: #cccccc !important;
  color: #575757 !important;

  &:hover {
    background-color: white !important;
    color: #1e1e1e !important;
  }
}
