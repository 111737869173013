.react-checkbox-tree {
    width: max-content;
}

.rct-checkbox[aria-disabled="true"] {
    color: #939393;
}

.rct-checkbox * {
    width: unset;
}

.rct-node-icon {
    display: none;
}

.main-grid {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-auto-rows: min-content;
    grid-gap: 20px;
    max-width: 100%;

    &.trade-points {
        grid-template-columns: 300px 1fr;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 5px;

        tr {
            border-bottom: 1px solid #ffffff;
            &.l1 {
                background-color: #f5f5f5;
            }
            &.l2 {
                background-color: #ffe5e5;
            }
            &.l3 {
                background-color: #fee8ff;
            }
            &.l4 {
                background-color: #e0ffff;
            }
            &.l5 {
                background-color: #e7c8f1;
            }
            &.l6 {
                background-color: #efe3ad;
            }
            &.p {
                background-color: #e8ffe5;
            }

            &.header {
                th > div {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    align-items: center;
                }
            }
            td {
                &.l1 {
                    background-color: #f5f5f5;
                }
                &.l2 {
                    background-color: #ffe5e5;
                }
                &.l3 {
                    background-color: #fee8ff;
                }
                &.l4 {
                    background-color: #e0ffff;
                }
                &.l5 {
                    background-color: #e7c8f1;
                }
                &.l6 {
                    background-color: #efe3ad;
                }
                &.p {
                    background-color: #e8ffe5;
                }
            }
        }

        //tr:nth-child(even) {
        //    background-color: #fff; // Change this to the color you want for odd rows
        //}
        //
        //tr:nth-child(odd) {
        //    background-color: #eaf2ff; // Change this to the color you want for even rows
        //}

        th {
            background-color: #fff;
            padding: 10px;
        }

        td {
            padding: 10px;
        }

        th, td {
            text-align: left;
        }
    }
}

//.data-grid {
//    display: grid;
//    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
//    grid-auto-rows: min-content;
//    grid-gap: 10px;
//
//    & > div {
//        padding: 10px;
//        border: 1px solid #e0e0e0;
//    }
//
//    .rowSpan2 {
//        grid-row: span 2;
//    }
//
//    .colSpan2 {
//        grid-column: span 2;
//    }
//}
